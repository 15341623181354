<script>
export default {
  name: 'ew-details-placeholder',
  props: {
    ratio: {
      type: [Boolean, Number],
      default: 16 / 10,
    },
  },
  setup(props) {
    const paddingBottom = props.ratio
      ? `${(1 / Number(props.ratio)) * 100}%`
      : null
    const imagePlaceholderStyle = {
      paddingBottom,
    }

    return {
      imagePlaceholderStyle,
    }
  },
}
</script>

<template>
  <div class="grid gap-[30px] p-5 sm:grid-cols-3 sm:p-0 md:gap-10">
    <div class="max-w-full md:w-80">
      <div
        class="animate-pulse rounded-lg bg-strong"
        :style="imagePlaceholderStyle"
      />
      <div class="mt-[30px] space-y-2.5">
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
      </div>
    </div>
    <div class="hidden w-full sm:col-span-2 sm:block">
      <div class="h-[30px] w-1/2 animate-pulse rounded-lg bg-strong" />
      <div class="mt-5 h-6 w-2.5 animate-pulse rounded-lg bg-strong" />
      <div class="mt-5 h-24 w-4/5 animate-pulse rounded-lg bg-strong" />

      <div class="mt-[30px] space-y-2.5">
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
        <div class="h-[15px] animate-pulse rounded-lg bg-strong" />
      </div>
    </div>
  </div>
</template>
